/*font title*/
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap');
/*font body*/
@import url('https://fonts.googleapis.com/css2?family=Anybody:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  /*background-color: #F5F5F5;*/
  background-color: #EBEBEB;
  /*background-color: #3f3d3d;*/
}

/*PARTIE PROJET*/
/*PLAYGROUND*/
.playground {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 65vh;
  overflow: hidden;
}

/*CARD*/
.card-project {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  max-height: 550px;
  width: 22em;
  border-radius: 10px;
  transition: 0.3s;
  margin: 15px;
  padding: 0.5em;
  list-style: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  /*transition: opacity 0.3s linear 2s;*/
}

.card-project h1 {
  font-size: 1.9rem;
  /*color: #2a9d8f;*/
  font-weight: bold;
  margin: 0;
  padding: 0.5em 0;
}

.card-project h2 {
  font-size: 1.6em !important;
  margin-bottom: 3px;
}

.card-project h3 {
  font-size: 1.3rem;
  margin-bottom: 3px;
}

.card-project h4 {
  font-size: 0.8rem;
}

.card-project p {
  font-size: 0.9em !important;
  margin-bottom: 0.1em;
  text-align: justify;
  white-space: pre-wrap;
  line-height: normal;
}

.card-project a {
  color: whitesmoke;
}

.card-project:hover .infos {
  opacity: 1;
  transform: scaleY(1);
}

.card-project:hover .infos a :hover {
  font-weight: bold;
}

.card-project .infos {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1em 25px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  height: 100%;
  max-width: 22em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0;
  transform: scaleY(0);
  transition: 0.3s;
}

.card-project img {
  height: 200px;
  width: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.tools-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  margin: 1em 0;
}

.tools-logo img {
  height: 30px;
  width: 30px;
  margin: 0 3px;
  box-shadow: none;
}

.github-logo img {
  margin: 8px 0;
  min-width: 0.8vw;
  height: auto;
}

.category {
  position: absolute;
  top: 5.5em;
  left: -2em;
  width: 11em;
  padding: 0.5rem;
  color: #ffffff;
  text-align: center;
  font-size: 0.65rem;
  font-weight: bold;
  z-index: 1;
  transform: rotate(-45deg);
  transform-origin: left top;
}